import { Divider } from 'antd'
import styled from 'styled-components'
import { Col } from '../antGrid'

export const PageSectionContainer = styled.div<{ $bottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%
    ${({ $bottomBorder, theme }) =>
      $bottomBorder ? `border-bottom: 1px solid ${theme.colors.architecture3}` : ''};
`

export const PageSectionRow = styled.div<{
  $isSubsection?: boolean
  $wrapActionsForSmallerScreen: boolean
}>`
  display: flex;
  flex-wrap: ${({ $wrapActionsForSmallerScreen }) =>
    $wrapActionsForSmallerScreen ? 'wrap' : 'nowrap'};
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: auto;
  padding: 0 2.5rem;
  background-color: ${({ $isSubsection, theme }) =>
    $isSubsection ? theme.colors.bgPrimary : theme.colors.bgSecondary};

  border-bottom: ${({ $isSubsection, theme }) =>
    $isSubsection ? '' : `1px solid ${theme.colors.architecture3}`};

  @media (min-width: 1300px) {
    flex-wrap: nowrap;
  }
`

export const NavigationCol = styled(Col)<{
  $reduceMargin?: boolean
  $hasSubtitle?: boolean
  $reducePadding?: boolean
}>`
  display: flex;
  align-items: center;
  padding: ${({ $reducePadding }) => ($reducePadding ? '0.5rem 0' : '1rem 0')};
  padding-bottom: ${({ $hasSubtitle }) => ($hasSubtitle ? '0.5rem' : '')};
  /* TODO: can we remove this? */
  > * {
    margin: 0 ${({ $reduceMargin }) => ($reduceMargin ? '2rem' : '4rem')} 0 0;
  }
  width: 70%;
`

export const ActionsCol = styled(Col)<{ $isSubsection?: boolean; $fullWidthActions?: boolean }>`
  display: flex;
  padding-top: ${({ $isSubsection }) => ($isSubsection ? '0.5rem' : '1rem')};
  padding-bottom: ${({ $isSubsection }) => ($isSubsection ? '0' : '1rem')};
  align-items: center;
  width: ${({ $fullWidthActions }) => ($fullWidthActions ? '100%' : 'auto')};
`

export const SpacedContent = styled.div<{
  $reduceGap?: boolean
  $removeTopPadding?: boolean
  $reduceContentTopPadding?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ $reduceGap }) => ($reduceGap ? '0.5rem' : '2.5rem')};
  flex: 1;
  padding: ${({ $removeTopPadding, $reduceContentTopPadding }) => {
    if ($removeTopPadding) {
      return '0 2.5rem 1.5rem'
    }
    if ($reduceContentTopPadding) {
      return '0.5rem 2.5rem'
    }
    return '1.5rem 2.5rem'
  }};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const LoadingContainer = styled.div`
  flex: 1;
  padding: 0 2.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const Subtitle = styled.p`
  margin: 0;
  padding: 0.5rem 2.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const StyledDivider = styled(Divider)`
  height: 2rem;
  color: ${({ theme }) => theme.colors.architecture3};
  margin: 0 1rem 0 1rem;
`
