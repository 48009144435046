import { pickBy } from 'lodash'
import {
  EntityDataType,
  FactoidOrigin,
  HighYoYReason,
  LARGE_VARIANCE_REASONS,
  NO_REASON,
  PortfolioDataInsightsReverseFieldMap,
  Standard,
  Theme,
} from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { EntityWithDetailsAndSDGs, InsightResults } from '../../../generated/facts'
import { transformSource } from '../../../models/source'
import { ReverseFieldMap } from '../../../queryBuilder'
import { Camelize } from '../../../utils'

export type Entity = Omit<Camelize<EntityWithDetailsAndSDGs>, 'entityId' | 'alignedSdgs'> & {
  id: number
  alignedSdgs: Standard[]
  themes: Theme[]
}

export type EntityFilters = Entity & {
  withDetails?: boolean
  revenue?: number
  factsetId?: string
}

export const transformEntity = <EntityDataProps extends Camelize<EntityWithDetailsAndSDGs>>({
  entityId,
  name,
  themes,
  isCovered,
  alignedSdgs,
  ...rest
}: EntityDataProps): Entity => ({
  ...pickBy(rest, valueIsDefined),
  id: entityId,
  name,
  themes: (themes || []) as Theme[],
  isCovered,
  alignedSdgs: alignedSdgs
    ? alignedSdgs?.map((sdg) => ({
        id: parseInt(sdg.majorCode as string, 10),
        standardClass: sdg.standardClass,
        majorCode: sdg.majorCode,
        fullCode: undefined,
        description: undefined,
        link: sdg.link,
        colour: sdg.colour,
      }))
    : [],
})

export const transformHighYoyReasons = (
  highYoyReasons: Camelize<InsightResults['high_yoy_reasons']>,
): HighYoYReason[] | undefined =>
  highYoyReasons
    ?.map((highYoyReason) => {
      if (
        highYoyReason.detail !== NO_REASON &&
        highYoyReason.reference &&
        valueIsDefined(highYoyReason.reference.pageNum)
      ) {
        return {
          variant: 'withReference' as const,
          factId: highYoyReason.factId,
          id: highYoyReason.factAnnotationId,
          // annotation_type, ie., High YoY variance, but we don't get it from this endpoint
          type: undefined,
          detail:
            LARGE_VARIANCE_REASONS.find((reason) => reason.name === highYoyReason.detail)?.name ||
            '',
          sourceId: highYoyReason.reference.sourceId,
          pageNum: highYoyReason.reference.pageNum,
          referenceId: highYoyReason.reference.referenceId,
          metricName: highYoyReason.metricName,
          source: highYoyReason.reference?.source
            ? transformSource(highYoyReason.reference.source)
            : undefined,
        }
      } else if (highYoyReason.detail === NO_REASON) {
        return {
          variant: 'base' as const,
          id: highYoyReason.factAnnotationId,
          factId: highYoyReason.factId,
          // annotation_type, ie., High YoY variance, but we don't get it from this endpoint
          type: undefined,
          detail: highYoyReason.detail,
          metricName: highYoyReason.metricName,
        }
      }
      return undefined
    })
    .filter(valueIsDefined)

export type EntityWithQuestionDetails = Omit<
  Camelize<InsightResults>,
  | 'entityId'
  | 'isCovered'
  | 'themes'
  | 'dataOriginUrls'
  | 'dataType'
  | 'factoidOrigin'
  | 'isHighYoy'
  | 'highYoyReasons'
> & {
  id: number
  isCovered: boolean | undefined
  themes: Theme[]
  dataOriginUrls: string[]
  dataType: EntityDataType
  factoidOrigin: FactoidOrigin | undefined
  isHighYoY: boolean
  highYoYReasons: HighYoYReason[] | undefined
}

export type EntityWithQuestionDetailsFilters = EntityWithQuestionDetails &
  EntityFilters &
  PortfolioDataInsightsReverseFieldMap

export const transformEntityDataByQuestionId = <
  EntityDataByQuestionIdProps extends Camelize<InsightResults>,
>({
  entityId,
  isCovered,
  themes,
  dataOriginUrls,
  factoidOrigin,
  dataType,
  isHighYoy,
  highYoyReasons,
  company,
  portfolio,
  ...rest
}: EntityDataByQuestionIdProps) => ({
  ...rest,
  id: entityId,
  isCovered,
  themes: themes as Theme[],
  dataOriginUrls: dataOriginUrls || [],
  dataType: (dataType === 'Calculated' ? 'Standardised' : dataType) as EntityDataType,
  factoidOrigin: factoidOrigin
    ? {
        ...factoidOrigin,
        factoidId: factoidOrigin.factoidId,
        description: factoidOrigin?.description,
        dataType: (factoidOrigin?.dataType === 'Calculated'
          ? 'Standardised'
          : factoidOrigin?.dataType) as EntityDataType,
      }
    : undefined,
  company,
  portfolio,
  isHighYoY: isHighYoy || false,
  highYoYReasons: transformHighYoyReasons(highYoyReasons),
})

export const reverseEntityWithQuestionDetailsFieldMap: ReverseFieldMap<
  keyof EntityWithQuestionDetails & PortfolioDataInsightsReverseFieldMap
> = {
  id: 'entity_id',
  name: 'name',
  isCovered: 'is_covered',
  primaryIsin: 'primary_isin',
  headquarters: 'headquarters',
  sector: 'sector',
  industry: 'industry',
  themes: 'themes',
  latestReport: 'latest_report',
  marketCap: 'market_cap',
  revenue: 'revenue',
  dataOriginUrls: 'data_origin_urls',
  dataType: {
    key: 'data_type',
    transform: (dataType: EntityDataType) =>
      dataType === 'Standardised' ? 'Calculated' : dataType,
  },
  highYoYReasons: 'high_yoy_reasons',
  isHighYoY: 'is_high_yoy',
  factoidOrigin: 'factoid_origin',
  alignedSdgs: 'aligned_sdgs',
  'company.impact': 'company_impact',
  'company.evicIntensity': 'company_evic_intensity',
  'company.revenueIntensity': 'company_revenue_intensity',
  'company.impactYoy': 'company_impact_yoy',
  'company.impactYoyDelta': 'company_impact_yoy_delta',
  'portfolio.impact': 'portfolio_impact',
  'portfolio.evicIntensity': 'portfolio_evic_intensity',
  'portfolio.revenueIntensity': 'portfolio_revenue_intensity',
  'portfolio.impactYoy': 'portfolio_impact_yoy',
}
